import { render, staticRenderFns } from "./materialSelfExam.vue?vue&type=template&id=a7da68ae&scoped=true&"
import script from "./materialSelfExam.vue?vue&type=script&lang=js&"
export * from "./materialSelfExam.vue?vue&type=script&lang=js&"
import style0 from "./materialSelfExam.vue?vue&type=style&index=0&id=a7da68ae&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a7da68ae",
  null
  
)

export default component.exports