<template>
  <div class="chapters-list">
    <div class="chapters-list-top">
      <p>请选择组卷的章节</p>
      <div class="btn" @click="checkAll">{{checkAllStatus ? '取消全选' : '选择全部章节'}}</div>
    </div>

    <div class="chapters-cont-list">
      <div class="chapters-list-item" v-for="item in dataList" :key="item.id">
        <div  class="chapters-list-item-tit display_space_between" @click="showItem(item)">
          <div>{{item.node.name}}</div>
          <div class="chapters-list-item-tit-btn" @click.stop="checkAllItem(item,item.refName)">{{item.allDataNum == item.selectDataIds.length ? '全不选' : '全选'}}</div>
        </div>
        <div class="chapters-list-item-cont" :style="{height: item.show ? (item.allExpandNum) * 45 + 'px' : '0px'}">
          <el-tree
              :ref="item.refName"
              :data="item.children"
              node-key="node_child_id"
              label="name"
              show-checkbox
              default-expand-all
              @check="(data,status)=>{checkNode(data,status,item)}"
              @node-expand="(data)=>nodeExpand(item,data)"
              @node-collapse="(data)=>nodeCollapse(item,data)"
          >
            <span slot-scope="{node,data}" class="category-item">
              <span>{{data.node.name}}</span>
            </span>
          </el-tree>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "chapters.vue",
  data(){
    return {
      dataList:[],
    }
  },
  props:{
    mapNodeIds:{
      type:String,
      default:''
    },
    isEdit:{
      type:Boolean,
      default:true,
    }
  },
  watch:{
    mapNodeIds:{
      handler(){
        this.mapId = this.$route.query.mapId || '';
        this.getDetail();
      },
      immediate:true
    }
  },
  created(){


  },
  computed:{
    checkAllStatus(){
      let len = this.dataList.filter((item)=>{
        return item.selectDataIds.length == item.allDataNum;
      }).length;

      if(len == this.dataList.length){
        return true;
      }else{
        return false;
      }
    }
  },

  methods:{
    getDetail(){
      let params = {
        id:this.mapId
      };
      this.api.dataset.mapDetail(params).then((res)=>{
        this.dataList = res.info.tree;
        let nodeIds = this.mapNodeIds.split(',');

        this.dataList.forEach((item,index)=>{
          this.$set(item,'refName','tree'+index);
          this.$set(item,'selectDataIds',[]);


          // if(index == 0){
          //   this.$set(item,'show',true);
          //   if(!item.allDataNum){
          //     let num = this.sumNum(item.children);
          //     this.$set(item,'allDataNum',num);
          //   }
          //
          // }else{
          //   this.$set(item,'show',true);
          //   if(!item.allDataNum){
          //     let num = this.sumNum(item.children);
          //     this.$set(item,'allDataNum',num);
          //   }
          // }
          this.$set(item,'show',true);
          let num = this.sumNum(item.children);
          this.$set(item,'allDataNum',num);
          this.$set(item,'allExpandNum',num);

          this.$nextTick(()=>{
            this.$refs['tree'+index][0].setCheckedKeys(nodeIds);
            let selectDataIds = this.$refs['tree'+index][0].getCheckedKeys();
            this.$set(item,'selectDataIds',selectDataIds);

          })
          // this.formatData(item.children);
        })
        this.$nextTick(()=>{
          let allIds = this.getAllIds();
          this.$emit('change',allIds);
        })

      })
    },
    sumNum(data){
      let num = 0;
      data.forEach((item)=>{
        if(item.children.length){
          num += this.sumNum(item.children);
          num++;
        }else{
          num++;
        }
      })
      return num;
    },
    showItem(data){
      data.show = !data.show;
    },
    checkAllItem(data,name){

      if(!this.isEdit) return;
      if(data.allDataNum == data.selectDataIds.length){
        this.$set(data,'selectDataIds', []);
        this.$refs[name][0].setCheckedKeys([]);
      }else{
        let ids = this.allIds(data.children);
        this.$set(data,'selectDataIds', ids);
        this.$refs[name][0].setCheckedKeys(ids);
      }
      let allIds = this.getAllIds();
      this.$emit('change',allIds);
    },
    checkAll(){
      if(!this.isEdit) return;
      if(this.checkAllStatus){
        this.dataList.forEach((item)=>{
          this.$set(item,'selectDataIds', []);
          this.$refs[item.refName][0].setCheckedKeys([]);
        })
      }else{
        this.dataList.forEach((item)=>{
          let ids = this.allIds(item.children);
          this.$set(item,'selectDataIds', ids);
          this.$refs[item.refName][0].setCheckedKeys(ids);
        })
      }
      let allIds = this.getAllIds();
      this.$emit('change',allIds);
    },
    allIds(data,status,name){
      let ids = [];
      data.forEach((item)=>{

        if(item.children.length){
          let arr = this.allIds(item.children);
          ids.push(...arr);
          ids.push(item.node_child_id)

        }else{
          ids.push(item.node_child_id)
        }
      })
      return ids;
    },
    checkNode(curData,status,data){
      if(!this.isEdit) return;
      data.selectDataIds = Object.values(status.checkedKeys);
      let allIds = this.getAllIds();
      this.$emit('change',allIds);
    },
    getAllIds(){
      let arr = [];
      this.dataList.forEach((item)=>{
        if(item.selectDataIds.length){
          arr.push(...item.selectDataIds);
        }
      })
      return arr;
    },
    nodeExpand(data,curData){
      data.allExpandNum = data.allExpandNum + curData.children.length;
    },
    nodeCollapse(data,curData){
      data.allExpandNum = data.allExpandNum - curData.children.length;
    }
  }
}
</script>

<style scoped lang="scss">
.chapters-list{
  height: 100%;
  overflow-y: auto;
  .chapters-list-top{
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    >p{
      font-size: 16px;
      font-weight: bold;
      color: #333333;
    }
    .btn{
      width: 120px;
      height: 28px;
      background: #FE9200;
      border-radius: 4px;
      color: #FFFFFF;
      text-align: center;
      line-height: 28px;
      cursor: pointer;
    }
  }
  .chapters-cont-list{
    margin-top: 20px;
    font-weight: bold;
    color: #333333;
    .chapters-list-item{
      margin-bottom: 10px;

      .chapters-list-item-tit{
        padding: 0 20px;
        height: 41px;
        background: #F6F7FA;
        color: #333333;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #dcdee2;

        .chapters-list-item-tit-btn{
          width: 60px;
          height: 28px;
          background: #5782FF;
          border-radius: 4px;
          color: #FFFFFF;
          text-align: center;
          line-height: 28px;
          cursor: pointer;
        }
      }
      .chapters-list-item-cont{
        margin-top: 10px;
        height:0px;
        transition:height 0.5s;
        overflow: hidden;

        .chapters-list-item-cont-li{
          color: #666666;
          height: 130px;
          border-bottom: 1px solid #E0E0E0;

          .chapters-list-item-cont-li-tit{
            margin-bottom: 10px;
            font-size: 28px;
            color: #333333;
          }
          .chapters-list-item-cont-li-btn{
            width: 130px;
            height: 50px;
            background: #4A79FF;
            border-radius: 25px;
            font-weight: 500;
            color: #FFFFFF;
            text-align: center;
            line-height: 50px;
          }
        }
        .chapters-list-item-cont-li:last-child{
          border-bottom:none;
        }
      }
    }
  }
}
</style>
<style>
.chapters-list .el-tree-node__content{
  height: 44px !important;
}
</style>
