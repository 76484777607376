<template>
<div class="material-self-exam">
  <MyBreadcrumb></MyBreadcrumb>
  <div class="material-self-exam-cont">
    <div class="material-self-exam-cont-l chapters" v-if="mapId">
      <ChaptersList @change="changeChapter" ></ChaptersList>
    </div>
    <div class="material-self-exam-cont-r">
      <div class="material-self-exam-cont-r-tit">请设置生成方式</div>
      <div class="material-self-exam-cont-r-num" >已选章节共计可抽取<span>{{ allDataCount }}题</span>生成试卷</div>
      <div class="material-self-exam-cont-r-num" style="margin-top: 10px">默认测试时长<span>120</span>分钟 ，满分<span>100</span>分，<span>60</span>分及格</div>
      <div class="tit">
        <p>设置难度比例：</p>
        <Tooltip class="list-icon">
          <Icon type="ios-help-circle-outline" size="16" />
          <div slot="content">
            <p>【不限】简单:一般:困难 = 3:5:2</p>
            <p>【简单】简单:一般:困难 = 3:1:1</p>
            <p>【一般】简单:一般:困难 = 1:3:1</p>
            <p>【困难】简单:一般:困难 = 1:1:3</p>
          </div>
        </Tooltip>
      </div>
      <div class="select-list">
        <p :class="levelId == item.id ? 'active' : ''" v-for="item in levelList" :key="item.id" @click="changeType('levelId',item.id)">{{item.name}}</p>
      </div>
      <div class="tit">
        <p>选择题目数量：</p>
      </div>
      <div class="select-list">
        <p :class="dataCont == item.id ? 'active' : ''" v-for="item in dataContList" :key="item.id" @click="changeType('dataCont',item.id)">{{item.name}}</p>
      </div>
      <div class="material-self-exam-cont-r-btn">
        <div @click="cancel">取消</div>
        <div class="active" @click="startExam">开始答题</div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import ChaptersList from './components/common/chaptersList';
export default {
  name: "materialSelfExam.vue",
  data(){
    return{
      allChapterIds:[],
      mapId:'',
      levelList:[
        {
          name:'不限',
          id:0,
        },
        {
          name:'简单',
          id:1,
        },
        {
          name:'一般',
          id:2,
        },
        {
          name:'困难',
          id:3,
        },
      ],
      levelId:0,
      dataContList:[
        {
          name:'30题',
          id:30,
        },
        {
          name:'50题',
          id:50,
        }
      ],
      dataCont:30,
      categoryId:'',
      allDataCount:0,
      courseId:'',
    }
  },
  components:{
    ChaptersList
  },
  created(){
    this.mapId = this.$route.query.mapId || '';
    this.categoryId = this.$route.query.categoryId || '';
    this.courseId = this.$route.query.courseId || '';
  },
  methods:{
    changeType(name,id){
      this[name] = id;
    },
    changeChapter(data){
      this.allChapterIds = data;

      let params = {
        map_id:this.mapId,
        node_id:this.allChapterIds.join(',')
      };
      this.api.dataset.datasetCategoryDataCount(params).then((res)=>{
        this.allDataCount = res.data_count;
      })
    },
    startExam(){
      if(!this.allChapterIds.length){
        this.$Message.warning('请选择章节');
        return;
      }
      if(this.allDataCount < this.dataCont){
        this.$Message.warning('已选章节试题数量不足，请重新选择！');
        return;
      }
      // if(this.allDataCount  == 0){
      //   this.$Message.warning('请选择试题');
      //   return;
      // }

      let params = {
        op:'quick',
        generate_type:0,
        type:1,
        generate_data_rule:3,
        generate_id:this.categoryId,
        map_node_ids_str:this.allChapterIds.join(','),
        level:this.levelId,
        data_count:this.allDataCount > this.dataCont ? this.dataCont : this.allDataCount,
        timelong:120,
        pass_score:60
      };

      if(this.courseId){
        params.generate_id = this.courseId;
        params.from_type = 5;
        params.from_id = this.courseId;
      }



      this.api.dataset.trainPublish(params).then((res)=>{
        //创建成功
        //组卷信息  传level
        // let infoParams = {
        //   op:'baseInfo',
        //   train_id:res.train_id,
        //   level:this.levelId,
        //   data_count:this.allDataCount > this.dataCont ? this.dataCont : this.allDataCount,
        //   timelong:120,
        //   pass_score:60
        // }
        // this.api.dataset.trainPublish(infoParams).then((res)=>{
        //   //发布
        //   let publishParams = {
        //     op:'release',
        //     train_id:res.train_id,
        //   }
        //   this.api.dataset.trainPublish(publishParams).then((res)=>{
        //     //发布成功进入考试
        //
        //     this.$router.push({
        //       path:'/trainingcamp/pre-exam',
        //       query:{
        //         id:res.train_id,
        //         isSelf:true
        //       }
        //     })
        //   })
        // })

        let trainId = res.train_id;
        let params = {
          action:'trainUserStart',
          train_id:trainId
        };

        this.api.dataset.trainExecute(params).then((res)=>{
          this.$router.push({
            path:'/exam',
            query:{
              id:trainId,
              isSelf:true
            }
          })
        })
      })
    },
    cancel(){
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="scss">
.material-self-exam{
  padding: 20px;
  height: 100%;

  .material-self-exam-cont{
    //padding-top: 10px;
    display: flex;
    justify-content: flex-start;
    font-size: 14px;
    height: calc(100% - 40px);


    .material-self-exam-cont-l{
      padding: 20px;
      flex: 1;
      width: 0;
      margin-right: 10px;
      background: #FFFFFF;
      height: 100%;
      overflow-y: auto;


    }
    .material-self-exam-cont-r{
      padding: 20px;
      width: 556px;
      background: #FFFFFF;
      height: 100%;
      overflow-y: auto;
      .material-self-exam-cont-r-tit{
        font-size: 16px;
        font-weight: bold;
        color: #333333;
      }
      .material-self-exam-cont-r-num{
        margin-top: 30px;
        padding-left: 14px;
        width: 400px;
        height: 40px;
        background: #F6F7FA;
        border-radius: 6px;
        font-size: 14px;
        color: #333333;
        line-height: 40px;
        >span{
          padding: 0 5px;
          color:#5782FF;
          font-weight: bold;
        }
      }
      .material-self-exam-cont-r-btn{
        margin-top: 60px;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        >div{
          width: 90px;
          height: 36px;
          border: 1px solid #999999;
          border-radius: 4px;
          color: #999999;
          text-align: center;
          line-height: 36px;
          cursor: pointer;
        }
        .active{
          margin-left: 15px;
          background: #4A79FF;
          border-radius: 4px;
          border:none;
          color: #FFFFFF;
        }
      }
      .tit{
        margin-top: 20px;
        margin-bottom: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #333333;
      }
      .select-list{
        display: flex;
        justify-content: flex-start;
        >p{
          margin-right: 20px;
          width: 106px;
          height: 50px;
          background: #F6F7FA;
          border-radius: 6px;
          text-align: center;
          line-height: 50px;
          font-size: 16px;
          font-weight: bold;
          color: #333333;
          cursor: pointer;
        }
        .active{
          background: #5782FF;
          color:#FFFFFF;
        }
      }
    }
  }
}
</style>
<!--<style>-->
<!--.chapters .el-tree-node__content{-->
<!--  height: 44px !important;-->
<!--}-->
<!--</style>-->

